<template>
  <div class="about-wrap">
    <vue-nav activeIndex='about'></vue-nav>
    <div class="bl"><img src="../assets/images/aboutbaner.png" alt=""></div>
    <div class="mov">
      <h2 id="introduction">公司介绍</h2>
      <p>上海湛鸿网络科技有限公司是柔婷&诗婷美容连锁集团旗下的新事业，母公司拥有30年直营美容院管理运营经验，全国有600家直营美容院。
        新事业致力于美业数字化的发展，有三个版块：美业数字化平台、化妆品日化线新零售、化妆品专业线加盟。美业数字化平台“美业汇”致力于为美容院提供一站式数字化智慧解决方案，从店务管理、顾客预约、服务点评、员工管理到网络拓客等进行全栈服务；化妆品新零售品牌“安尚米啦”（essenselle)源自法国，是崇尚自然主义的鲜活植物护肤专家，拥享布列塔尼净澈海域、阿尔卑斯山无瑕灵境和普罗旺斯蓝色海岸植物资源的鲜活能量，融合现代科技匠心研发的“智慧绿色”护肤品；化妆品专业线加盟品牌“香榭迪尔”（Champs
        Dela）是源自于法国的科技美容品牌。

      </p>
      <video controls autoplay name="media" class="opshow"
        src="https://mlf-idc.oss-cn-shenzhen.aliyuncs.com/video/meiyehui.mp4">
      </video>
    </div>
    <div class="about2">
      <div class="two">
        <h2>企业文化</h2>
        <div class="box">
          <span>"</span>
          <strong>品质至上</strong>
          <p>美业汇坚持以品质为根、诚信为本、
            服务至上的理念为宗旨，竭诚打造
            产品的每一个细节，坚守每一步
            服务品质。</p>
        </div>
        <div class="box">
          <span>"</span>
          <strong>求真务实</strong>
          <p>始终坚持以“实践者”精神为行动准则，
            以行践言，以踏实、稳健的脚步开拓前
            进的道路。</p>
        </div>
        <div class="box">
          <span>"</span>
          <strong>责任为魂</strong>
          <p>主动履责，勇于担责，尽职尽责；
            责任是一种义务，责任是一种付出，
            责任更是一种回报,这是我们持续
            发展的最坚强保障。</p>
        </div>
        <div class="box">
          <span>"</span>
          <strong>创新共赢</strong>
          <p>美业汇身处美业店务管理，唯一不变的
            就是变化本身，美业互联网日新月异、
            无论外界风云如何变幻，我们永葆创新精
            神不变。</p>
        </div>
      </div>

    </div>
    <div class="about3">
      <h2 id="course">公司发展历程</h2>
      <img src="../assets/images/fzsss_03.jpg" alt="">
    </div>
    <div class="about4">
      <div class="relame">
        <h2 id="contactUs">联系我们</h2>
        <!-- <baidu-map class="left">
        </baidu-map> -->
        <div class="left"> <img src="../assets/image/map.png" alt=""></div>
        <div class="right">
          <p>公司地址：上海市闵行区黎安路1126号莘吴之星7楼</p>
          <p class="o1">联系电话：136-0200-1885</p>
          <div class="right1">
            <img src="../assets/images/qer.png" alt="">
            <p class="isp">关注我们</p>
          </div>
          <div class="right1 anima">
            <p class="ios">ios应用下载</p>
          </div>
          <div class="right1 anima1">
            <p class="and">android下载</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import bnav from './components/b-nav'
// import baidumap from './components/map'
export default {
  components: {
    'vue-nav': bnav,
    // 'baidu-map': baidumap
  },
  name: 'index',
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }

}
</script>
<style lang="scss" scoped>
@import "../assets/about.scss";
</style>